import React, { Fragment } from 'react';
import './DynamicListItem.scss';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableHandle } from 'react-sortable-hoc';
import { MoreActionsButton } from 'components/buttons/MoreActionsButton/MoreActionsButton';
import DynamicCell from 'components/DynamicCell/DynamicCell';
import Icon from 'components/Icon/Icon';
import { IconButton } from '@mui/material';
import * as ev from "expr-eval";
const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
interface DynamicListItemProps {
    data: any;
    dragHandleEnabled?: boolean;
    leftCells?: Array<any>;
    rightCells?: Array<any>;
    style?: any;
    actions?: Array<any>;
    moreActions?: Array<any>;
    onSelectMoreAction?: (val: any) => void;
    onSelectAction?: (val: any) => void;
    onChange?: (val: any) => void;
}
const DynamicListItem: React.FC<DynamicListItemProps> = ({
    data = {},
    leftCells = [],
    rightCells = [],
    moreActions = [],
    actions = [],
    dragHandleEnabled = false,
    style = {},
    onSelectMoreAction,
    onSelectAction,
    onChange,
}) => {
    const evaluateShow = (exp: any) => {
        try {
            return exp ? ev.Parser.evaluate(exp, data) : true;
        } catch (error) {
            console.error("Error evaluating expression: ", error);
            return false; // Retorna false en caso de error
        }
    };
    return (
        <div className='dynamic-list-item-container' style={{ zIndex: 99999999, ...style }}>
            <div className="dynamic-list-item-cells">
                <div className='left-cells'>
                    {leftCells.map((item: any, subIndex: number) => {
                        const shouldShowCell = (item.showIf && evaluateShow(item.showIf)) || !item.showIf;
                        return (
                            <Fragment key={subIndex}>
                                {shouldShowCell && (
                                    <div>
                                        <DynamicCell data={data[item.name]} config={item} extraData={data} onChange={(val: any) => {
                                            if (onChange) onChange(val);
                                        }} />
                                    </div>
                                )}
                            </Fragment>
                        )

                    })}
                </div>
                <div className='right-cells'>
                    {rightCells.map((item: any, subIndex: number) => {
                        const shouldShowCell = (item.showIf && evaluateShow(item.showIf)) || !item.showIf;
                        return (
                            <Fragment key={subIndex}>
                                {shouldShowCell && (
                                    <div>
                                        <DynamicCell data={data[item.name]} config={item} extraData={data} onChange={(val: any) => {
                                            if (onChange) onChange(val);
                                        }} />
                                    </div>
                                )}
                            </Fragment>
                        )

                    })}
                </div>
            </div>
            <div className="dynamic-list-item-actions">
                {actions.map((action: any, index: number) => {
                    return <IconButton
                        key={index}
                        color="inherit"
                        onClick={() => {
                            if (onSelectAction) {
                                onSelectAction({
                                    actionName: action?.name,
                                    //actionVisibleName: action?.visibleName,
                                    config: action,
                                    currentItem: data
                                });
                            }
                        }}
                        aria-label={action.name}
                    >
                        <Icon name={action.icon} />
                    </IconButton>
                })}
                {dragHandleEnabled && <DragHandle />}
                {
                    moreActions.length > 0 &&
                    <MoreActionsButton
                        id="list-item-more-actions-button"
                        options={moreActions}
                        context={data}
                        onSelectItem={(res: any) => {
                            console.log(res)
                            if (onSelectMoreAction) {
                                onSelectMoreAction({
                                    actionName: res?.actionName,
                                    actionVisibleName: res?.actionVisibleName,
                                    config: res?.config,
                                    currentItem: data
                                });
                            }
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default DynamicListItem