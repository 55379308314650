import React, { useEffect, useState } from "react";
import "./ProductVariantList.scss";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "components/Icon/Icon";
const ProductVariantList = ({
  attributesWithValues,
  onChange,
  defaultCost = 0,
  defaultPrice = 0,
  data,
  readonly = false,
  isTrackable = false,
  isSellable = false,
}: // defaultInitialStock = 0,
  // defaultReorderPoint = 0,
  // defaultReorderQuantity = 0,
  any) => {
  const [variants, setVariants] = useState<any[]>([]);
  const [combinations, setCombinations] = useState<any[]>([]);

  useEffect(() => {
    // Función recursiva para obtener todas las combinaciones de valores seleccionados
    const getCombinations = (
      arrays: any[],
      index = 0,
      result: any[] = [],
      prefix: string = "",
      ids: number[] = []
    ) => {
      if (index === arrays.length) {
        if (prefix !== "") {
          // Evita agregar una cadena vacía como variante
          result.push({
            name: prefix.slice(0, -1), // Elimina la última barra "/"
            attribute_value_ids: [...ids], // Copia los IDs
            cost: parseFloat(defaultCost), // Agrega el costo por defecto a cada variante
            price: parseFloat(defaultPrice), // Agrega el precio por defecto a cada variante
            code: "",
            reorder_point: 0,
            reorder_quantity: 0,
            // initial_stock: defaultInitialStock,
            // reorder_point: defaultReorderPoint,
            // reorder_quantity: defaultReorderQuantity,
          });
        }
      } else {
        const selectedValues = arrays[index].values.filter(
          (v: any) => v.selected
        );
        if (selectedValues.length === 0) {
          getCombinations(arrays, index + 1, result, prefix, ids); // Si no hay valores seleccionados, avanza al siguiente atributo
        } else {
          for (const value of selectedValues) {
            getCombinations(
              arrays,
              index + 1,
              result,
              prefix + value.value + "/",
              [...ids, value.id]
            );
          }
        }
      }
      return result;
    };

    // Obtener las combinaciones de los valores seleccionados
    if (attributesWithValues) {
      const newCombinations = getCombinations(attributesWithValues);
      setCombinations(newCombinations);
    }
  }, [
    attributesWithValues,
    defaultCost,
    defaultPrice,
    // defaultInitialStock,
    // defaultReorderPoint,
    // defaultReorderQuantity,
  ]);

  useEffect(() => {
    if (onChange) onChange(variants);
  }, [variants]);

  useEffect(() => {
    if (combinations) setVariants(combinations);
  }, [combinations]);
  useEffect(() => {
    setTimeout(() => {
      setVariants(data);
    }, 100);
  }, []);
  // Función para manejar los cambios de precio en las variantes
  const handleInputChange = (index: number, newValue: string, key: string) => {
    const updatedVariants = [...variants];
    updatedVariants[index][key] =
      key != "code" ? parseFloat(newValue) : newValue;
    setVariants(updatedVariants);
  };
  const handleDeleteRow = (index: number) => {
    setVariants((prevData: any) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };
  return (
    <div className="product-variant-list-container">
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th style={{ textAlign: "left", width: "130px" }}>Código</th>
              {isTrackable && (
                <>
                  {/* <th style={{ textAlign: "right", width: "130px" }}>
                    Stock inicial
                  </th> */}

                  <th style={{ textAlign: "right", width: "130px" }}>
                    P. de reposición
                  </th>
                  <th style={{ textAlign: "right", width: "130px" }}>
                    C. a reponer
                  </th>
                </>
              )}
              <th style={{ textAlign: "right", width: "130px" }}>Costo</th>
              {isSellable && (
                <>
                  <th style={{ textAlign: "right", width: "130px" }}>
                    Precio de venta
                  </th>
                </>
              )}
              {!readonly && <th></th>}
            </tr>
          </thead>
          <tbody>
            {variants?.map((variant: any, index: number) => (
              <tr key={index}>
                <td data-column-header={"Nombre"}>
                  <span>{variant.name}</span>{" "}
                </td>
                <td data-column-header={"Código"}>
                  {readonly ? (
                    <div className="readonly-cell">
                      <span>{variant?.code}</span>
                    </div>
                  ) : (
                    <div className="input-wrapper">
                      <input
                        type="text"
                        style={{ textAlign: "left" }}
                        value={variant?.code || ""}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value, "code")
                        }
                      />
                    </div>
                  )}
                </td>
                {isTrackable && (
                  <>
                    {/* <td data-column-header={"Stock inicial"}>
                      {readonly ? (
                        <div className="readonly-cell">
                          <span>{variant?.initial_stock}</span>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <input
                            style={{ textAlign: "right" }}
                            type="number"
                            value={variant?.initial_stock || null}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                e.target.value,
                                "initial_stock"
                              )
                            }
                          />
                        </div>
                      )}
                    </td> */}

                    <td data-column-header={"P. de reposición"}>
                      {readonly ? (
                        <div className="readonly-cell">
                          <span>{variant?.reorder_point}</span>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <input
                            style={{ textAlign: "right" }}
                            type="number"
                            value={variant?.reorder_point || 0}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                e.target.value,
                                "reorder_point"
                              )
                            }
                          />
                        </div>
                      )}
                    </td>
                    <td data-column-header={"Cant. a reponer"}>
                      {readonly ? (
                        <div className="readonly-cell">
                          <span>{variant?.reorder_quantity}</span>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <input
                            style={{ textAlign: "right" }}
                            type="number"
                            value={variant?.reorder_quantity || 0}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                e.target.value,
                                "reorder_quantity"
                              )
                            }
                          />
                        </div>
                      )}
                    </td>
                  </>
                )}
                <td data-column-header={"Costo"}>
                  {readonly ? (
                    <div className="readonly-cell">
                      <strong>$</strong>
                      <span>{variant?.cost}</span>
                    </div>
                  ) : (
                    <div className="input-wrapper">
                      <strong>$</strong>
                      <input
                        style={{ textAlign: "right" }}
                        type="number"
                        value={variant?.cost || 0}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value, "cost")
                        }
                      />
                    </div>
                  )}
                </td>
                {isSellable && (
                  <>
                    <td data-column-header={"Precio de venta"}>
                      {readonly ? (
                        <div className="readonly-cell">
                          <strong>$</strong>
                          <span>{variant?.price}</span>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <strong>$</strong>
                          <input
                            style={{ textAlign: "right" }}
                            type="number"
                            value={variant?.price || 0}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value, "price")
                            }
                          />
                        </div>
                      )}
                    </td>
                  </>
                )}
                {!readonly && (
                  <td>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteRow(index)}
                      color="error"
                    >
                      <Icon name="delete" />
                    </IconButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { ProductVariantList };
