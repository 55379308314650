import { useEffect, useState } from "react";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { InputField } from "components/fields/InputField/InputField";

import { Grid, Box } from "@mui/material";
import "./SettingsExpandedCard.scss";
import { ImageField } from "components/fields/ImageField/ImageField";
import { ColorField } from "components/fields/ColorField/ColorField";
import { Spinner } from "components/Spinner/Spinner";
import urls from "config/urls";

const SettingsExpandedCard = (props: any) => {
  const { title } = props;
  const [data, setData] = useState({} as any);
  const [processing, setProcessing] = useState(false);
  const getData = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.get(`${urls.server}/api/settings/get`);
      const data = res?.data;
      console.log(data);
      setData(data);
      setProcessing(false);
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="project-settings-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      {processing ? (
        <div className="spinner-wrapper">
          <Spinner visible={processing} />
        </div>
      ) : (
        <div className="expanded-card-body">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputField
                id="project-name"
                defaultValue={data?.name}
                label={"Nombre del projecto"}
                editable={true}
                editPath={"settings/update-name"}
                mode={"edit"}
                onEdit={(value: string) => {

                }}
              //editable
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id="project-visible-name"
                defaultValue={data?.visibleName}
                label={"Nombre a mostrar"}
                editable={true}
                editPath={"settings/update-visible-name"}
                mode={"edit"}
                onEdit={(value: string) => {

                }}
              //editable
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id="logo-width"
                defaultValue={data?.logoWidth}
                label={"Ancho del logo"}
                editable={true}
                editPath={"settings/update-logo-width"}
                mode={"edit"}
                onEdit={(value: string) => {

                }}

              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageField
                id="project-logo"
                defaultValue={data?.logo}
                label={"Logo"}
                editable={true}
                editPath={"settings/update-logo"}
                onChange={(value: string) => {
                  console.log("imagen", value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageField
                id="project-favicon"
                defaultValue={data?.favicon}
                label={"Favicon"}
                editable={true}
                editPath={"settings/update-favicon"}
                accept=".png"
                onChange={(value: string) => {
                  console.log("imagen", value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ColorField
                defaultValue={data?.primaryColor}
                label={"Color principal"}
                editable={true}
                editPath={"settings/update-primary-color"}
                onEdit={(value: string) => {
                  console.log("color", value);
                }}
                onChange={(value: string) => {
                  console.log("color", value);
                }}
                disablePrimary={true}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {/* <Fab
        className="fab"
        variant="extended"
        color="primary"
        aria-label="save"
        onClick={() => {}}
      >
        <Icon name="check" sx={{ mr: 1 }} />
        Guardar
      </Fab> */}
    </div>
  );
};

export { SettingsExpandedCard };
