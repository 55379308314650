import React, {
    Fragment, useState,
    useEffect
} from 'react'
import * as ev from "expr-eval";
import { RadioItemField } from './itemFields/RadioItemField/RadioItemField';
import { CheckboxItemField } from './itemFields/CheckboxItemField/CheckboxItemField';
import './ItemFields.scss';

const ItemFields = ({ fields, extraData = {},
    defaultData = {}, onChange }: any) => {
    let initialValues: any = {};

    fields.map((field: any) => {
        switch (field.type) {
            case "radio":
                initialValues[field.name] = field.defaultValue || field.options.length > 0 ? field.options[0].name : null;
                break;
            case "checkbox":
                initialValues[field.name] = field.defaultValue || false;
                break;
            default:
                break;
        }
    });

    const [values, setValues] = useState({
        ...initialValues,
        ...defaultData,
        ...extraData,
    } as any);
    const handleChangeField = (
        fieldName: any,
        value: any
    ) => {
        setValues((prev: any) => {
            const data = { ...prev };
            data[fieldName] = value;

            return data;
        });
    };
    useEffect(() => {
        onChange(values);
    }, [values]);
    const evaluateShow = (exp: any) => {
        try {
            return exp ? ev.Parser.evaluate(exp, values) : true;
        } catch (error) {
            console.error("Error evaluating expression: ", error);
            return false; // Retorna false en caso de error
        }
    };
    return (
        <div className='item-fields-container'>

            {fields.map((field: any, index: number) => {
                const shouldShowField = (field.showIf && evaluateShow(field.showIf)) || !field.showIf;
                return (
                    <Fragment key={index}>
                        {shouldShowField && (
                            <div className='field-wrapper'>
                                {(() => {
                                    switch (field.type) {

                                        case "radio":
                                            return (
                                                <>
                                                    <RadioItemField
                                                        label={field.visibleName}
                                                        onChange={(value: any) =>
                                                            handleChangeField(field.name, value)
                                                        }
                                                        defaultValue={values[field.name]}
                                                        options={field.options}
                                                        name={field.name}
                                                        description={field.description}

                                                    />
                                                </>
                                            );
                                        case "checkbox":
                                            return (
                                                <CheckboxItemField
                                                    label={field.visibleName}
                                                    onChange={(value: any) =>
                                                        handleChangeField(field.name, value)
                                                    }
                                                    defaultValue={values[field.name]}

                                                />
                                            );

                                    }
                                })()}
                            </div>
                        )}
                    </Fragment>
                );
            })}

        </div>
    )
}

export default ItemFields