import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import InterpolatedContent from "components/InterpolatedContent/InterpolatedContent";
import recordService from "services/recordService";
import interpolateString from "utils/interpolateString";
import { Toast } from "components/Toast/Toast";

function DeleteConfirmationDialog({
  isOpen,
  confirmationMessage,
  data,
  deletePath,
  onClose,
  onDeleteSuccess,
}: any) {

  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");

  const handleDelete = async () => {
    setProcessing(true);
    try {
      const res = await recordService.delete({
        deletePath: `${interpolateString(data, deletePath)}`,
      });

      setProcessing(false);
      onDeleteSuccess();
      onClose();
      if (res) {

      }
    } catch (error: any) {
      setProcessing(false);
      onClose();
      setToastIsOpen(true)
      setToastMessage(error.message);
      setToastSeverity('error');
    }
  };
  return (

    <>
      {isOpen &&
        <>
          <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="delete-confirmation-dialog-title"
            aria-describedby="delete-confirmation-dialog-description"
          >
            <DialogTitle className="delete-confirmation-dialog-title">
              {"Confirmar antes de eliminar"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="delete-confirmation-dialog-description">
                <InterpolatedContent template={confirmationMessage} data={data} />

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} autoFocus disabled={processing}>
                Cancelar
              </Button>
              <Button onClick={handleDelete} color="primary" disabled={processing}>
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
          <Toast
            isOpen={toastIsOpen}
            onClose={() => setToastIsOpen(false)}
            message={toastMessage}
            severity={toastSeverity}
          />
        </>
      }


    </>
  );
}

export default DeleteConfirmationDialog;