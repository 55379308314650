import { useState, useEffect, useRef } from "react";
import "./ImageCropperField.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import urls from "config/urls";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  IconButton,
} from "@mui/material";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import ImageCropperModal from "components/modals/ImageCropperModal/ImageCropperModal";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Spinner } from "components/Spinner/Spinner";
import Icon from "components/Icon/Icon";

const ImageCropperField = (props: any) => {
  const [croppedImg, setCroppedImg] = useState<string>("");
  const [processing, setProcessing] = useState(false);
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    onEdit,
    id,
    recordId,
    //readonly,
    folder = "images",
    tableName,
    name,
    aspect = 1,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState<any>(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);
  const removeImg = () => {
    setCroppedImg("");
    onChange(null);
    setValue(null);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    /**
     * Reinicio el valor del input
     */
    let input: any = document.getElementById(id);
    input.value = null;
  };
  const handleChange = async (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    handleOpenModal();
  };
  const saveImage = async (content: string) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        file_content: content,
        extension: "jpg",
        folder: folder,
      });
      const resData = await res.data;
      setValue(resData?.path);
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };
  return (
    <>
      <div className="image-cropper-field-container">
        <div className="field-header">
          {label && (
            <label>
              <span>{label}</span>
            </label>
          )}
          {editable && (
            <div>
              {(mode == 'edit' && editable) && (
                <FieldEditControls
                  value={value}
                  fieldName={name}
                  editPath={editPath}
                  onEditStart={() => {
                    setIsEditEnabled(true);
                  }}
                  onEditCancel={(originalValue) => {
                    setIsEditEnabled(false);
                    setValue(originalValue);
                  }}
                  onEditEnd={(success: boolean, originalValue) => {
                    setIsEditEnabled(false);
                    if (!success) {
                      setValue(originalValue);
                    }
                  }}
                />
              )}
            </div>
          )}

        </div>

        <div className="field-body">
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-img-wrapper">
              {value ? (
                <img src={`${urls.server}${value}`} alt="miniatura" />
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    color: "#9cb0be",
                    borderRadius: "10px",
                    display: "flex",
                    width: "100px",
                    height: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon name="nubelon" style={{ fontSize: "60px" }} />
                </div>
              )}
            </div>
          ) : (
            <>
              {processing ? (
                <div className="spinner-wrapper">
                  <Spinner size="sm" />
                </div>
              ) : (<>
                {value && (
                  <div className="cropped-img-wrapper">

                    <img src={`${urls.server}${value}`} />
                    <IconButton
                      className="remove-btn"
                      aria-label="delete"
                      onClick={removeImg}
                    >
                      <Icon name="delete" />
                    </IconButton>
                  </div>
                )}
                {!value && (
                  <div className="add-image-btn">
                    <input
                      id={id}
                      accept="image/*"
                      type="file"
                      onChange={handleChange}
                      ref={fileInputRef}
                    />
                    <div>
                      <div>
                        <InsertPhotoIcon />
                        <span>Seleccionar imagen</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
              </>
              )}
            </>
          )}
        </div>
      </div>
      <ImageCropperModal
        isOpen={openModal}
        image={image}
        aspect={aspect}
        onClose={(res: any) => {
          setOpenModal(false);
          if (res) {
            saveImage(res);
          }
        }}
      />

    </>
  );
};

export { ImageCropperField };
