import React, { useState, useEffect } from "react";
import Modal from "components/Modal/Modal";
import "./AddressFormModal.scss";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import styles from "config/styles";
import markerIcon from "./marker.svg";
import InfoIcon from "@mui/icons-material/Info";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import keys from "config/keys";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Fab } from "@mui/material";
import { InputField } from "components/fields/InputField/InputField";
import { Toast } from "components/Toast/Toast";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import Icon from "components/Icon/Icon";
const DEFAULT_LOCATION = { lat: -34.603722, lng: -58.381592 };
function Map({ onMarkerMove, onChange, coordinates }: any) {
  //const defaultCoords = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const defaultCoords = {
    lat: coordinates?.lat ? coordinates.lat : DEFAULT_LOCATION.lat,
    lng: coordinates?.lng ? coordinates.lng : DEFAULT_LOCATION.lng,
  };
  const [center, setCenter] = useState(defaultCoords);
  const [selected, setSelected] = useState(defaultCoords);
  // const [center, setCenter] = useState(defaultCoords);
  // const [selected, setSelected] = useState(defaultCoords);
  useEffect(() => {
    console.log(coordinates)
    if ((!coordinates?.lat || !coordinates?.lng) && navigator.geolocation) {

      // De lo contrario, tratamos de obtener las coordenadas del navegador
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          setCenter({ lat, lng });
          setSelected({ lat, lng });
        },
        (error) => {
          console.error("Error obteniendo la geolocalización: ", error);
          // En caso de error, las coordenadas permanecen en el valor por defecto.
        }
      );
    } else {
      console.log("Geolocalización no soportada por este navegador.");
      // Si la geolocalización no es soportada, las coordenadas permanecen en el valor por defecto.
    }
  }, []);

  useEffect(() => {
    onChange(selected);
    onMarkerMove();
  }, [selected]);
  const onDragMarker = (coord: any) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelected({ lat: lat, lng: lng });
    onMarkerMove();
  };

  return (
    <div className="map-container">

      <div className="places-autocomplete-wrapper">
        <div className="info-wrapper">
          <div>
            <InfoIcon />{" "}
            <span>
              Busca un domicilio o mueve el marcador hasta el lugar deseado
            </span>
          </div>
        </div>
        <div className="input-wrapper">
          <div>
            <Icon name="search" />
            <PlacesAutocomplete
              setSelected={setSelected}
              setCenter={setCenter}

            // placeholder={"Buscar domicilio"}
            />
          </div>
        </div>


      </div>

      <GoogleMap
        zoom={16}
        center={center}
        //mapContainerClassName="map-container"
        options={{
          styles: mapStyles,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false
        }}
        mapContainerStyle={{
          position: "absolute",
          // height: "100%",
          // width: "100%",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {selected && (
          <Marker
            position={selected}
            draggable={true}
            onDragEnd={onDragMarker}
            //icon={iconPin}
            icon={{
              url: markerIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}
      </GoogleMap>

    </div>
  );
}
const mapStyles = styles.googleMap;
const PlacesAutocomplete = ({ setSelected, setCenter }: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setCenter({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => {
          console.log(e.target.value, status, data);
          setValue(e.target.value);
        }}
        disabled={!ready}
        className="combobox-input"
        placeholder="Buscar domicilio"
      />
      {/* <InputField
        placeholder="Buscar domicilio"
      /> */}
      {/* <input type="text" onChange={(e) => {
        console.log(e.target.value, status, data);
        setValue(e.target.value);
      }} /> */}
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              // <Fragment key={place_id}>
              //   <ComboboxOption value={description} />
              // </Fragment>

              <ComboboxOption key={place_id} value={description} />

            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

interface ModalProps {
  isOpen: boolean;
  title?: string;
  //coordinates?: any;
  mode: 'create' | 'edit' | 'preview'
  data?: any;
  onClose: (refreshOnClose: boolean, data?: any) => void;
}

const AddressFormModal: React.FC<ModalProps> = ({
  isOpen,
  title = "",
  //coordinates,
  mode,
  data,
  onClose,
}) => {
  const [processing, setProcessing] = useState(false);
  // const [data, setValues] = useState({
  //   lat: null,
  //   lng: null,
  //   name: null,
  //   full_address: null,
  //   observations: null,
  //   city_name: null,
  //   province_name: null,
  //   country_name: null
  // } as any);
  const [values, setValues] = useState({} as any);
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  } as any);
  const [isMapMoving, setIsMapMoving] = useState(false);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const [libraries] = useState(['places'] as any);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: keys.googleMaps || "",
    libraries: libraries
  });
  // useEffect(() => {
  //   setValues(data);
  // }, [data])
  const onSave = async () => {
    if (mode === "preview") {
      onClose(refreshOnClose, values);
    } else {
      setProcessing(true);
      try {
        if (mode === "edit") {
          let url = `${urls.server}/api/addresses`;

          const dataToSend = values;
          const res = await AxiosInterceptor.put(url, dataToSend);
          const resData = await res?.data;
          const { message } = resData;
          setToastMessage(message.toString());
          onClose(resData);
          setToastIsOpen(true);
          setToastSeverity("success");
        } else {

          const dataToSend = {
            ...values,
          };
          let url = `${urls.server}/api/addresses`;
          const res = await AxiosInterceptor.post(url, dataToSend);
          const resData = await res?.data;
          const { message } = resData;
          setToastMessage(message.toString());
          onClose(resData);
          setToastIsOpen(true);
          setToastSeverity("success");
        }
        setProcessing(false);
      } catch (error: any) {
        setProcessing(false);
        setToastMessage(error.toString());
        setToastIsOpen(true);
        setToastSeverity("error");
      }
    }

  };
  const getAddressFromCoordinates = async () => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation?.lat},${currentLocation?.lng}&key=${keys.googleMaps}`);
      const data = await response.json();
      console.log(data);
      if (data.status === 'OK') {
        const fullAddress = data?.results[0]?.formatted_address;
        const cityName = getCityName(data?.results[0]?.address_components)
        const provinceName = getProvinceName(data?.results[0]?.address_components)
        const countryName = getCountryName(data?.results[0]?.address_components)
        setValues((prevValue: any) => {
          const newValue = { ...prevValue };
          newValue.full_address = fullAddress;
          newValue.city_name = cityName;
          newValue.province_name = provinceName;
          newValue.country_name = countryName;
          return newValue;
        });
      } else {
        console.log('Geocoding API error:', data.status);
      }
    } catch (error) {
      console.error('Geocoding request failed', error);
    }
  }

  function getCityName(addressArray: any) {
    if (addressArray) {
      const localityObj = addressArray.find((item: any) => item.types.includes('locality'));
      return localityObj ? localityObj.long_name : '';
    }
    return null
  }
  function getProvinceName(addressArray: any) {
    if (addressArray) {
      const localityObj = addressArray.find((item: any) => item.types.includes('administrative_area_level_1'));
      return localityObj ? localityObj.long_name : '';
    }
    return null
  }
  function getCountryName(addressArray: any) {
    if (addressArray) {
      const localityObj = addressArray.find((item: any) => item.types.includes('country'));
      return localityObj ? localityObj.long_name : '';
    }
    return null
  }
  useEffect(() => {
    if (isOpen) {
      setIsMapMoving(false);
    } else {
      setValues({})
    }
    if (data && !isMapMoving) {
      setValues(data)
    } else if (currentLocation?.lat && currentLocation?.lng && isOpen && isMapMoving) {
      console.log('se movio');
      setValues((prevData: any) => {
        const newData = { ...prevData };
        newData.lat = currentLocation?.lat;
        newData.lng = currentLocation?.lng;
        return newData;
      });
      getAddressFromCoordinates();
    }

  }, [currentLocation, data, isOpen])
  useEffect(() => {
    console.log(values)
  }, [values])
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <Modal
        title={title}
        className={'address-form-modal-container'}
        isOpen={isOpen}
        onClose={() => onClose(refreshOnClose)}
      >
        <Map
          onChange={(res: any) => {


            console.log(res)
            setCurrentLocation((prevValue: any) => {
              const newValue = { ...prevValue };
              newValue.lat = res?.lat;
              newValue.lng = res?.lng;
              return newValue;
            });
          }}
          onMarkerMove={() => {
            setIsMapMoving(true);
          }}
          coordinates={{
            lat: values?.lat,
            lng: values?.lng
          }} />
        <div className="form-wrapper">
          <div className="input-wrapper">
            <InputField
              label={"Latitud"}
              size="sm"
              defaultValue={values?.lat}
              onChange={(res: any) => {
                setValues((prevData: any) => {
                  const newData = { ...prevData };
                  newData.lat = res;
                  return newData;
                });
              }}
            /></div>
          <div className="input-wrapper">
            <InputField
              label={"Longitud"}
              size="sm"
              defaultValue={values?.lng}
              onChange={(res: any) => {
                setValues((prevData: any) => {
                  const newData = { ...prevData };
                  newData.lng = res;
                  return newData;
                });
              }}
            /></div>
          <div className="input-wrapper">
            <InputField
              label={"Nombre"}
              description="Puede ser el nombre de un establecimiento"
              size="sm"
              defaultValue={values?.name}
              onChange={(res: any) => {
                setValues((prevData: any) => {
                  const newData = { ...prevData };
                  newData.name = res;
                  return newData;
                });
              }}
            /></div>
          <div className="input-wrapper">
            <InputField
              label={"Dirección"}
              size="sm"
              defaultValue={values?.full_address}
              onChange={(res: any) => {
                setValues((prevData: any) => {
                  const newData = { ...prevData };
                  newData.full_address = res;
                  return newData;
                });
              }}
            /></div>
          <div className="input-wrapper">
            <InputField
              label={"Observación"}
              size="sm"
              defaultValue={values?.observations}
              onChange={(res: any) => {
                setValues((prevData: any) => {
                  const newData = { ...prevData };
                  newData.observations = res;
                  return newData;
                });
              }}
            /></div>
        </div>
        <Fab
          className="fab"
          color="primary"
          aria-label="save"
          onClick={() => {
            onSave();
          }}
          disabled={processing}
          style={{ opacity: processing ? .6 : 1 }}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 0
          }}
        >
          <Icon name="check" />
        </Fab>
      </Modal>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export { AddressFormModal };