import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Autocomplete } from "../../Autocomplete/Autocomplete";
import { FormModal } from "../../modals/FormModal/FormModal";
import { RadioField } from "../RadioField/RadioField";
import { Parser } from "expr-eval";
import { PastelColorField } from "../PastelColorField/PastelColorField";
import { ImageField } from "../ImageField/ImageField";
import Icon from "components/Icon/Icon";
import { SliderField } from "../SliderField/SliderField";
import { ColorField } from "../ColorField/ColorField";
import { InputField } from "../InputField/InputField";
import ConfigurationField from "../ConfigurationField/ConfigurationField";
import { IconPickerField } from "../IconPickerField/IconPickerField";
import { LongTextField } from "../LongTextField/LongTextField";
import { MoneyField } from "../MoneyField/MoneyField";
import { CheckboxField } from "../CheckboxField/CheckboxField";
import { MultiCheckboxField } from "../MultiCheckboxField/MultiCheckboxField";
import { GridField } from "../GridField/GridField";
const DynamicListInput = ({
  //setData,
  itemIndex,
  cell,
  item,
  handleInputChange,
  formData,
  extraData,
}: any) => {
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const evaluateFilter = (filter: any, formData: any, extraData: any) => {
    try {
      const parser = new Parser();
      const expr = parser.parse(filter);
      return expr.evaluate({ formData, extraData });
    } catch (error) {
      // Si hay un error, retornar el filtro sin cambios
      return filter;
    }
  };

  const prepareFilters = (filters: any, formData: any, extraData: any) => {
    return filters?.map((subArray: any) => {
      return subArray.map((item: any) => {
        if (item.includes("formData") || item.includes("extraData")) {
          return evaluateFilter(item, formData, extraData);
        } else {
          return item;
        }
      });
    });
  };
  const renderInput = (
    itemIndex: number,
    cell: any,
    value: any,
    onChange: any
  ) => {
    switch (cell.type) {
      case "longText":
        return (
          <LongTextField
            mode={cell.readonly ? "readonly" : null}
            label={cell.visibleName}
            defaultValue={value}
            onChange={onChange}
          />
        );
      case "integer":
        return (
          <InputField

            label={cell.visibleName}
            type="integer"
            defaultValue={value}
            mode={cell.readonly ? "readonly" : null}
            onChange={onChange}
          />
        );
      case "decimal":
        return (
          <InputField

            label={cell.visibleName}
            type="decimal"
            mode={cell.readonly ? "readonly" : null}
            defaultValue={value}
            onChange={onChange}
          />

        );
      case "money":
        return (

          <MoneyField
            mode={cell.readonly ? "readonly" : null}
            label={cell.visibleName}
            defaultValue={value}
            onChange={onChange}
          />
        );
      case "select":
        return (
          <>
            {value ? (
              <div className="selected-item">
                <span>{value[cell.primaryKey]}</span>
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => {
                    // setData((prevData: any) => {
                    //   const newData = [...prevData];
                    //   newData[itemIndex][cell.name] = null;
                    //   newData[itemIndex][`${cell.name}_id`] = null;
                    //   return newData;
                    // });
                  }}
                >
                  <Icon name="close" />
                </IconButton>
              </div>
            ) : (
              <div className="autocomplete-wrapper">
                <Autocomplete
                  // onSelect={(data: any) => {
                  //   console.log(data);
                  //   // if (data) {
                  //   //   setData((prevData: any) => {
                  //   //     const newData = [...prevData];
                  //   //     newData[itemIndex][cell.name] = data;
                  //   //     newData[itemIndex][`${cell.name}_id`] = data.id;
                  //   //     return newData;
                  //   //   });
                  //   // }
                  // }}
                  onSelect={onChange}
                  placeholder={cell.placeholder}
                  primaryKey={cell.primaryKey}
                  searchPath={cell.searchPath}
                  filters={prepareFilters(cell.filters, formData, extraData)}
                />
                {cell.formFields && (
                  <IconButton
                    aria-label="new record"
                    size="small"
                    onClick={() => {
                      setFormModalIsOpen(true);
                    }}
                  >
                    <Icon name="add" />
                  </IconButton>
                )}
              </div>
            )}
          </>
        );
      case "iconPicker":
        return (


          <IconPickerField
            label={cell.visibleName}
            onlyWith={cell.onlyWith}
            onlyTypes={cell.onlyTypes}
            defaultValue={value}
            onChange={onChange}
          />
        );
      case "checkbox":
        return (
          <>
            <CheckboxField
              label={cell.visibleName}
              defaultValue={value}
              onChange={onChange}
            />
          </>
        );
      case "list":
        return (
          <></>
        );
      case "object":
        return (
          <></>
        );
      case "slider":
        return (
          <SliderField
            label={cell.visibleName}
            defaultValue={value}
            onChange={onChange}
          />
        );
      case "text":
        return (
          <InputField
            label={cell.visibleName || ""}
            type="text"
            defaultValue={value}
            options={cell.options}
            onChange={onChange}
          />
        );
      case "radio":
        return (
          <RadioField
            label={cell.visibleName}
            defaultValue={value}
            options={cell.options}
            onChange={onChange}

          />
        );
      case "multiCheckbox":
        return (
          <MultiCheckboxField
            label={cell.visibleName}
            defaultValue={value || []}
            options={cell.options}
            onChange={onChange}
          />
        );
      case "pastelColor":
        return (
          <PastelColorField
            label={cell.visibleName}
            defaultValue={value || "#A597CC"}
            validations={cell.validations}
            onChange={onChange}
          />
        );
      case "color":
        return (
          <ColorField
            label={cell.visibleName}
            defaultValue={value}
            validations={cell.validations}
            onChange={onChange}
          />
        );
      case "configuration":
        return (
          <ConfigurationField
            label={cell.visibleName}
            defaultValue={value || []}
            validations={cell.validations}
            configurationType={cell.configurationType}
            onChange={onChange}
          />
        );
      case "image":
        return (
          <ImageField
            label={cell.visibleName}
            id={cell.name + "_" + itemIndex}
            defaultValue={value}
            validations={cell.validations}
            onChange={onChange}
          />
        );
      case "grid":
        return (
          <GridField
            defaultValue={value}
            label={cell.visibleName}
            description=""
            forceMobileView={true}
            cols={cell.cols}
            onChange={onChange}
          />
        );
      // default:
      //   return (
      //     <input
      //       className="text-input"
      //       type="text"
      //       value={value || ""}
      //       onChange={onChange}
      //     />
      //   );
    }
  };
  return (
    <>
      {renderInput(itemIndex, cell, item[cell.name], (res: any) => {

        return handleInputChange(res, itemIndex, cell.name)
      }

      )}
      <FormModal
        isOpen={formModalIsOpen}
        onClose={() => { setFormModalIsOpen(false) }}
        title={cell.formTitle}
        fields={cell.formFields}
      //tableName={cell.tableName}
      />
    </>
  );
};

export { DynamicListInput };
