import React, { useState, useEffect, useRef } from "react";
import "./ImageViewerModal.scss";
import Modal from "components/Modal/Modal";
import ImageZoom from "react-image-zooom";

interface ModalProps {
  isOpen: boolean;
  fileUrl: string | null;
  title: string;
  onClose: (refreshOnClose?: boolean) => void;
}
const ImageViewerModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  fileUrl,
  onClose
}) => {
  useEffect(() => {
    if (!isOpen) {

    }
  }, [isOpen]);

  const handleOnClose = () => {
    onClose();
  }

  return (
    <>
      <Modal
        title={title}
        className={'image-viewer-modal-container'}
        isOpen={isOpen}
        onClose={handleOnClose}
        style={{
          overflowY: 'hidden',
        }}
      >
        <div className="image-wrapper">
          {fileUrl && isOpen &&
            <ImageZoom src={fileUrl} alt={title} zoom={"200"} className="image-zoom" />
          }
        </div>
      </Modal>

    </>
  );
};

export { ImageViewerModal };
