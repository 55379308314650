
import React, { useState, useEffect } from 'react';
import './ChartFooterAnimation.scss';
import colors from 'config/colors';
import { Line } from 'react-chartjs-2';

const ChartFooterAnimation = () => {
  const [barHeights, setBarHeights] = useState([]);
  const [lineChartData, setLineChartData] = useState({
    labels: [],
    datasets: [],
  } as any);
  const [numberOfBars, setNumberOfBars] = useState(50);
  const barWidth = 100 / (1.5 * numberOfBars);
  const barSpacing = barWidth * 0.5;

  // Función para generar números aleatorios
  const generateRandomHeights: any = () => {
    return Array.from({ length: numberOfBars }, () => Math.floor(Math.random() * 100));
  };
  const generateRandomLineData: any = () => {
    const lineData = Array.from({ length: numberOfBars }, () => Math.floor(Math.random() * 100));
    const newLineChartData = {
      labels: Array.from({ length: numberOfBars }, (_, i) => i), // Labels can simply be the index
      datasets: [
        {
          //label: 'Line Dataset',
          data: lineData,
          fill: false,
          //backgroundColor: 'rgba(75,192,192,0.4)',
          //borderColor: 'rgba(75,192,192,1)',
          tension: 0.3,
          borderColor: colors.medium,
          backgroundColor: colors.medium,
        }
      ]
    };
    return newLineChartData;
  };
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: false,

      },
      tooltip: {
        enabled: false
      }
    },
    pointRadius: 4,
    pointHoverRadius: 4,
    pointBorderWidth: 0,

    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },

    }
  };

  useEffect(() => {
    setBarHeights(generateRandomHeights()); // Inicializar con valores aleatorios al montar
    setLineChartData(generateRandomLineData());
    const intervalId = setInterval(() => {
      setBarHeights(generateRandomHeights()); // Actualizar cada 2 segundos
      setLineChartData(generateRandomLineData());
    }, 2000);

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
  }, []);
  return (
    <div
      className="chart-footer-animation-container"
      style={{
      }}
    >
      <div
        className="column-chart-content"
        style={{
          width: "100%",
          height: "0",
          paddingBottom: "24%",
          position: "relative",
        }}
      >
        {barHeights?.map((height: number, index: number) => (
          <div
            key={index}
            className="chart-bar"
            style={{
              //backgroundColor: colors.light,
              backgroundColor: "#b6ccdb",
              width: `${barWidth}%`,
              height: `${height}%`,
              position: 'absolute',
              bottom: 0,
              left: `${index * (barWidth + barSpacing) + barSpacing / 2}%`
            }}
          />
        ))}
      </div>
      {lineChartData ? <div className='line-chart-wrapper'>
        <Line options={lineChartOptions} data={lineChartData} />
      </div> : null}

    </div>
  );
};

export default ChartFooterAnimation;