import "./MiniMultiLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MiniMultiLineChart = (props: any) => {
  const { data, height = 75, decimalPrecision = 2, onPointSelect } = props;
  const chartRef = useRef<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  const [selectedPoint, setSelectedPoint] = useState<any>({ datasetIndex: null, index: null });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        const index = element.index;
        const period = chartData.labels[index];
        const value = chartData.datasets[datasetIndex].data[index];
        const label = chartData.datasets[datasetIndex].label;
        const color = chartData.datasets[datasetIndex].backgroundColor
        console.log(chartData.datasets[datasetIndex]);
        if (onPointSelect) {
          onPointSelect({ period, value, label, color });
        }

        // Agrandar el punto clicado
        setSelectedPoint({ datasetIndex, index });
      }
    },
  };

  function adaptSingleBreakdownData(indicatorData: any) {
    const datasetsMap = new Map();
    const labels = indicatorData.map((item: any) => item.visible_period);

    indicatorData.forEach((period: any) => {
      period.data.forEach((item: any) => {
        if (!datasetsMap.has(item.label)) {
          datasetsMap.set(item.label, {
            label: item.label,
            data: new Array(indicatorData.length).fill(0),
            borderColor: item.color,
            backgroundColor: item.color,
            pointRadius: new Array(indicatorData.length).fill(3),
          });
        }
        const dataset = datasetsMap.get(item.label);
        const periodIndex = labels.indexOf(period.visible_period);
        dataset.data[periodIndex] = item.value.toFixed(decimalPrecision);
      });
    });

    const datasets = Array.from(datasetsMap.values());
    return {
      labels: labels,
      datasets: datasets,
    };
  }

  useEffect(() => {
    const adaptedData = adaptSingleBreakdownData(data);
    setChartData(adaptedData);
  }, [data]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      const lastDataIndex = chartData.labels.length - 1;
      const lastDatasetIndex = chartData.datasets.length - 1;

      chartRef.current.data.datasets.forEach((dataset: any, i: number) => {
        dataset.pointRadius = dataset.data.map((_: any, dataIndex: number) =>
          dataIndex === lastDataIndex && i === lastDatasetIndex ? 6 : 3
        );
      });

      chartRef.current.update('none');
    }
  }, [chartData]);

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current && chartData && selectedPoint.index !== null) {
        const { datasetIndex, index } = selectedPoint;

        chartRef.current.data.datasets.forEach((dataset: any, i: number) => {
          dataset.pointRadius = dataset.data.map((_: any, dataIndex: number) =>
            dataIndex === index && i === datasetIndex ? 6 : 3
          );
        });

        chartRef.current.update('none');
      }
    }, 100)
  }, [selectedPoint]);

  return (
    <div
      className="mini-multi-line-chart-container"
      style={{
        height: height + "px",
      }}
    >
      {chartData && <Line ref={chartRef} options={options} data={chartData} />}
    </div>
  );
};

export { MiniMultiLineChart };

