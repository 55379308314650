import React from "react";
import "./IndicatorGroupCard.scss";
import Icon from "../../../../components/Icon/Icon";
import { AnimatedNumber } from "../../../../components/AnimatedNumber/AnimatedNumber";
import { Tooltip } from "@mui/material";
const IndicatorGroupCard = ({ title, data }: any) => {
  const [indicators, setIndicators] = React.useState(data);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  return (
    <div className="indicator-group-card-container">
      <Tooltip
        placement="top"
        title={
          title
        }
      >
        <h2 className="card-title">{title}</h2>
      </Tooltip>
      <div>
        {indicators.length > 0 ? (
          <>
            <div className="btns-wrapper">
              {/* {JSON.stringify(indicators)} */}
              {indicators.map((item: any, index: number) => {
                return (
                  <a
                    key={index}
                    style={{
                      color: index == currentIndex ? item?.color : "#9cb0be",
                    }}
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                  >
                    <Icon name={item?.icon} />
                  </a>
                );
              })}
            </div>
            <div className="current-data">
              <div className="title">{indicators[currentIndex]?.label}</div>
              <span>
                <div className="animated-number-wrapper">
                  <AnimatedNumber
                    value={indicators[currentIndex]?.value}
                    duration="500"
                    color={indicators[currentIndex]?.color}
                    fontSize={
                      indicators[currentIndex]?.value > 9999 ? "16px" : "22px"
                    }
                    type="decimal"
                    decimalPrecision={indicators[currentIndex]?.decimalPrecision}
                  />
                </div>

                <div
                  className="measuremente-unit"
                  style={{ color: indicators[currentIndex]?.color }}
                >
                  {indicators[currentIndex]?.measurementeUnit}
                </div>
              </span>

              <strong className="period">
                {indicators[currentIndex]?.period}
              </strong>
            </div>
          </>
        ) : (
          <>-</>
        )}
      </div>
    </div>
  );
};

export { IndicatorGroupCard };
