import React, { useState } from "react";
import "./BoardCard.scss";
import { SortableKnob } from "react-easy-sort";
import { DataTableCard } from "../../cards/dataTable/components/DataTableCard/DataTableCard";
import { SettingsCard } from "../../cards/settings/components/SettingsCard/SettingsCard";
import { IndicatorCard } from "../../cards/indicator/components/IndicatorCard/IndicatorCard";
import { MyTasksCard } from "../../cards/tasks/components/MyTasksCard/MyTasksCard";
import { TasksCard } from "../../cards/tasks/components/TasksCard/TasksCard";
import { RankingCard } from "../../cards/ranking/components/RankingCard/RankingCard";
import { WorkflowsCard } from "../../cards/workflows/components/WorkflowsCard/WorkflowsCard";
import { WorkflowEntriesCard } from "../../cards/workflowEntries/components/WorkflowEntriesCard/WorkflowEntriesCard";
import { IndicatorGroupCard } from "cards/indicatorGroup/components/IndicatorGroupCard/IndicatorGroupCard";
import ExpandedCardModal from "components/modals/ExpandedCardModal/ExpandedCardModal";
import BoardModal from "../modals/BoardModal/BoardModal";
import { DataListCard } from "cards/dataList/components/DataListCard/DataListCard";
import { RingBindersCard } from "cards/ringBinders/components/RingBindersCard/RingBindersCard";
import { MyRingBindersCard } from "cards/ringBinders/components/MyRingBindersCard/MyRingBindersCard";
import Icon from "components/Icon/Icon";
import { MultiActionCard } from "cards/multiAction/components/MultiActionCard/MultiActionCard";

interface BoardCardProps {
  title: string;
  data: any;
  config?: any;
  type: string;
  isSortable: boolean;
  isExpandable: boolean;
  childCards?: any[];
  childCardsCount?: number;
  id: number;
  onChange?: () => void;
}

const BoardCard: React.FC<BoardCardProps> = ({ title, data, config, type, childCards = [], isSortable, id, isExpandable, childCardsCount = 0, onChange }) => {
  const [boardModalIsOpen, setBoardModalIsOpen] = useState(false);
  const [expandedCardModalIsOpen, setExpandedCardModalIsOpen] = useState(false);

  const CustomKnob = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
    return (
      <div ref={ref} style={{ cursor: "grab" }}>
        <Icon name="drag" />
      </div>
    );
  });
  const handleExpandCard = () => {
    setExpandedCardModalIsOpen(true);
    // onExpand(index);
  };
  return (
    <div className="board-card-container">
      <div>
        <div>
          {(() => {
            switch (type) {

              case "indicator":
                return (
                  <IndicatorCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );
              case "indicatorGroup":
                return (
                  <IndicatorGroupCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );


              case "myTasks":
                return <MyTasksCard title={title} data={data} config={config} />;
              case "tasks":
                return <TasksCard title={title} data={data} config={config} />;

              case "dataTable":
                return (
                  <DataTableCard title={title} data={data} config={config} />
                );
              case "settings":
                return (
                  <SettingsCard title={title} data={data} config={config} />
                );
              case "multiAction":
                return (
                  <MultiActionCard title={title} data={data} config={config} />
                );
              case "ranking":
                return (
                  <RankingCard title={title} data={data} config={config} />
                );
              case "ringBinders":
                return (
                  <RingBindersCard title={title} data={data} config={config} />
                );
              case "myRingBinders":
                return (
                  <MyRingBindersCard title={title} data={data} config={config} />
                );
              case "workflows":
                return (
                  <WorkflowsCard title={title} data={data} config={config} />
                );

              case "dataList":
                return (
                  <DataListCard title={title} data={data} config={config} />
                );

              case "workflowEntries":
                return (
                  <WorkflowEntriesCard
                    title={title}
                    data={data}
                    config={config}
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
        <div className="card-btns">
          {isExpandable ? (
            <button onClick={handleExpandCard}>
              <Icon name="fullscreen" />
            </button>
          ) : (
            <></>
          )}

          {/* {childCards?.length > 0 ? (
            <button
              onClick={() => {
                setBoardModalIsOpen(true);
              }}
            >
              <Icon name="cards" />
            </button>
          ) : (
            <></>
          )} */}
          {childCardsCount > 0 ? (
            <button
              onClick={() => {
                setBoardModalIsOpen(true);
              }}
            >
              <Icon name="cards" />
            </button>
          ) : (
            <></>
          )}
          {isSortable ? (
            <SortableKnob>
              <CustomKnob />
            </SortableKnob>
          ) : (
            <SortableKnob>
              <span></span>
            </SortableKnob>
          )}
        </div>
      </div>
      <BoardModal
        isOpen={boardModalIsOpen}
        title={title}
        data={childCards}
        parentCardId={id}
        onClose={() => {
          setBoardModalIsOpen(false)
        }}
      />
      <ExpandedCardModal
        isOpen={expandedCardModalIsOpen}
        onClose={() => {
          setExpandedCardModalIsOpen(false)
        }}
        onChange={onChange}
        data={data}
        config={config}
        type={type}
        title={title}
        id={id}
      />
    </div>
  );
};

export default BoardCard;
