import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import "./TabNavigator.scss";
import urls from "config/urls";
import { project } from "config/project";
import Icon from 'components/Icon/Icon';
import NotificationBadge from "components/NotificationBadge/NotificationBadge";
import { useEffect } from "react";

const TabNavigator = ({ options }: any) => {
  const location = useLocation();
  const { getUser } = useAuth();
  const isEven = options.length % 2 === 0;
  const tabClass = isEven ? "tab" : "tab-odd";
  const shouldShowNavigator = options.some((tab: any) => location.pathname === tab.path);

  if (!shouldShowNavigator) {
    return null;
  }

  return (
    <div className={`tab-navigator-container ${isEven ? '' : 'odd-tabs-container'}`}>
      <span>{project.version}</span>
      <div className="tab-links-container" style={{
        maxWidth: 100 * options.length + 40
      }}>
        {options.map((tab: any, index: number) => {
          const isActive = location.pathname === tab.path;
          const middleTabClass = !isEven && Math.floor(options.length / 2) === index ? 'middle-tab' : '';
          const proflieTabClass = tab.name == "profile" ? 'profile-tab' : '';
          return (
            <NavLink
              key={tab.name}
              className={`tab-link ${isActive ? "active-tab" : ""} ${tabClass} ${middleTabClass} ${proflieTabClass}`}
              to={tab.path}
            >
              {tab.name !== 'profile' ? (
                <>

                  <Icon name={tab.wrapperIcon} />
                  <Icon name={tab.icon} />
                  <span>{tab.visibleName}</span>
                  {
                    tab.name == 'notifications' ? <NotificationBadge /> : null
                  }
                </>
              ) : (
                getUser() && (
                  <div>
                    <img
                      src={`${urls.server}${getUser()?.profile_picture}`}
                      alt="profile"
                    />
                  </div>

                )
              )}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export { TabNavigator };
