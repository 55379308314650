import { useState, Fragment, useEffect } from "react";
import urls from "config/urls";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { RecordLinkingModal } from "components/modals/RecordLinkingModal/RecordLinkingModal";
import "./UsersCell.scss";
const UsersCell = (props: any) => {
  const {
    data,
    subtitle,
    sendPath,
    onChange,
    readonly,
    itemFields = []
  } = props;
  const [recordLinkingModalIsOpen, setRecordLinkingModalIsOpen] =
    useState(false);

  return (
    <>
      <div className="users-cell-container">
        <a
          onClick={() => {
            setRecordLinkingModalIsOpen(true);
          }}
        >
          {data.map((item: any, index: number) => {
            let marginRight = "0px";
            if (index == 0 && data.length > 2) {
              marginRight = "40px";
            } else if (index == 1 && data.length > 2) {
              marginRight = "20px";
            } else if (index == 0 && data.length == 2) {
              marginRight = "20px";
            } else if (index == 1 && data.length == 2) {
              marginRight = "0px";
            } else {
              marginRight = "0px";
            }
            return (
              <Fragment key={index}>
                {index < 2 && (
                  <img
                    key={index}
                    src={urls.server + item.profile_picture}
                    alt=""
                    style={{
                      //right: index == 0 && data.length >= 2 ? "-20px" : "-30px",
                      //left: index == 0 ? "0px" : "20px",
                      marginRight: marginRight,
                    }}
                  />
                )}
              </Fragment>
            );
          })}
          {data.length > 2 && (
            <div
            // style={{
            //   right: "-20px",
            // }}
            >
              + {data.length - 2}
            </div>
          )}
          {data.length == 0 && !readonly && (
            <span>
              <PersonAddIcon />
            </span>
          )}
          {data.length == 0 && readonly && (
            <span>
              -
            </span>
          )}
        </a>
      </div >
      <RecordLinkingModal
        isOpen={recordLinkingModalIsOpen}
        onClose={(items, refresh) => {
          setRecordLinkingModalIsOpen(false);
          if (refresh) {
            onChange();
          }
        }}
        title="Vincular usuarios"
        subtitle={subtitle}
        readonly={readonly}
        searchPath="users"
        linkPath={sendPath}
        primaryKey="name"
        secondaryKey="email"
        thumbnailKey="profile_picture"
        itemFields={itemFields}
        noContentIcon="users"
        noContentText="No hay usuarios vinculados"
        data={data}
      />
    </>
  );
};

export { UsersCell };
